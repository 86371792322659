.bigbox-config {
  h2 {
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: 1.5rem;
  }
  h1 {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1.5rem;
  }
  label {
    margin-bottom: 0.7em !important;
  }
}
