// button,
// input[type='submit'],
// input[type='reset'] {
//   background: none;
//   color: inherit;
//   border: none;
//   padding: 0;
//   font: inherit;
//   cursor: pointer;
//   outline: inherit;
// }

.sheduled-jobs-toolbar-box {
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: var(--success) !important;
}

.sheduled-jobs-toolbar {
  // background-color: green;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  //height: 8rem;
}

.sheduled-jobs-toolbar-row-outer {
  width: 85%;
}

.sheduled-jobs-toolbar-row {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  min-width: 20rem;
  max-width: 50rem;
  margin: 0.3rem 2rem;
  color: white;
}
.sheduled-jobs-toolbar-header-row {
  // background-color: var(--primary);
  margin: 0 1rem;
  color: white;
  min-width: 10rem;
  position: relative;
  display: inline-block;
  text-align: center;
}

.sheduled-jobs-toolbar-header {
  color: white;
  margin-top: -0.5rem;
}

.checkbox-box {
  min-width: 7rem;
  color: white;
}

.checkbox {
  margin-right: 0.3rem;
}

.view-jobs-container {
  max-height: 95vh;
  overflow: auto;
}

.sheduled-jobs-toolbar > .sheduled-jobs-toolbar-row-outer {
  border-bottom: 0.2rem solid white;
  padding-bottom: 0.23rem;
}

.sheduled-jobs-toolbar > .sheduled-jobs-toolbar-row-outer ~ .sheduled-jobs-toolbar-row-outer {
  border: none;
}

.scheduled-jobs-status-label {
  max-height: 48px;
  margin-top: 0;
  max-width: 16.5rem;
  margin-bottom: auto;
  position: relative;
  display: block;
  border-top-left-radius: 100rem;
  border-bottom-left-radius: 100rem;
}

.parameter-box-outer-job {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 45rem;
}

.parameter-box-job > * {
  width: 25rem;
}
