.calendar-box {
  margin: 0.5rem;
  border: 2px solid var(--primary);
  position: relative;
  padding: 1rem;
}

.calendar-cancel-job-button {
  cursor: pointer;
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
  color: var(--primary);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  transition: transform 0.2s; /* Animation */
}
.calendar-cancel-job-button:hover {
  transform: scale(1.3);
}

.scheduling-calendar {
}

.scheduled-calendars-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 2rem 0;
}
.calendar-job-number {
  position: absolute;
  bottom: 0.2rem;
  right: 0.4rem;
  color: var(--primary);
  font-weight: bold;
}
