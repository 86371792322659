.data-card {
  margin-bottom: 2rem;
  overflow: hidden;
  padding-bottom: 1rem;

  .title-container {
    height: 2.5em;
    padding: 1em;
  }

  .is-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .has-borders {
    .column {
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      padding-top: 0;
      padding-bottom: 0;
    }
    .column:not(:first-child) {
      border-left: solid 1px rgba(0, 0, 0, 0.4);
    }
  }
  .column {
    margin-bottom: 1.5rem;
  }

  .data-card-columns {
    padding-top: 0.5rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .column .title {
      height: 2em;
    }
  }
}
