.upload-file-box {
  .is-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    cursor: pointer;
  }
  label {
    pointer-events: none;
  }
}

.file-hovering label {
  background-color: var(--success-light);
  border: 3px solid var(--success);
}

.upload-file-options-box {
  .margin-right {
    margin-right: 1rem;
  }
}

.filter-list {
  padding: 2rem;
  border-radius: 2px;
}

.upload-filter-label {
  font-size: 0.8rem;
  opacity: 0.6;
}

.filter-row-delete {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: #ffcbcb;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  text-align: center;
  padding-bottom: 0.25rem;
  font-weight: 600;
  transition: 100ms;

  &:hover {
    background-color: #ff8282;
    cursor: pointer;
  }
}

.filter-column-styles {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 0.5rem;
}
