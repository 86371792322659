.bigbox-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #000;

  margin-top: 0;
  padding-top: 0;
  line-height: 1em;
}

.bigBox-time {
  margin-bottom: 1.5rem;
}

.data-boxes {
  border-top: 1px solid #eaeaea;
  flex-wrap: wrap;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .data-box {
    border: 1px solid #e6e6e6;
    min-width: 30%;
    margin: 0.5rem;
    p {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 1.1rem;
    }

    label {
      margin-top: 0;
      font-size: 0.9rem;
    }
  }
}
