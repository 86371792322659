.bigbox {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  padding: 1rem;
  width: 100%;
  overflow-y: visible;
  overflow-x: hidden;

  .dateinput {
    overflow: visible;
    padding: 0;

    .form-control {
      border: none;
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 1rem;
    }
  }

  .multi-string {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: visible;

    :nth-child(1) {
      margin-right: 1rem;
    }
  }

  .remove {
    border: 1px solid black;
    border-radius: 2rem !important;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 1.5rem;

    &:hover {
      background-color: black;
      color: white;
      cursor: pointer;
    }
  }

  .level-add {
    display: flex;
    :nth-child(1) {
      margin-left: 1rem;
    }
    align-items: center;
  }

  .add {
    border: 1px solid black;
    border-radius: 2rem !important;
    height: 1.5rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;

    &:hover {
      background-color: black;
      color: white;
      cursor: pointer;
    }
  }

  .label {
    margin-bottom: 0;
    padding-bottom: 0;
    line-height: 0;
  }

  .control {
    margin-bottom: 2rem;
  }

  .light {
    font-size: 0.8rem;
    color: grey;
  }
}
