.snackbar-container {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  width: 350px;
  z-index: 99999;

  .has-background-warning {
    color: black !important;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      margin-right: 1rem;
    }
  }
}
