.view-sms-container {
  display: flex;
  position: relative;
  max-height: 95vh;
  overflow: auto;
  max-width: 95vw;
  height: 5rem;

  .box {
    color: var(--primary);
  }

  button {
    margin-top: 1rem;
  }

  .data-loading-status {
    position: relative;
    height: 3rem;
    width: 7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .spinning-box {
    height: 2rem;
    width: 2rem;
    background-color: var(--info);
    border-radius: 100%;
  }
}
