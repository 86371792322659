// Colors
$primary: #34495e;
$info: #2c3e50;
$success: #18af90;
$light: #1ccaa7;
$darkdanger: hsl(348, 86%, 43%);
:root {
  --aside-width: 450px;
  --success: #18af90;
  --success-light: #18af9050;
  --darkdanger: hsl(348, 86%, 43%);
  --primary: #34495e;
  --primary-light: rgba(112, 131, 156, 0.7);
  --info: #2c3e50;
}

// Import only what you need from Bulma
@import '../../node_modules/bulma/bulma.sass';

// Dashboard CSS
html,
body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  aside {
    display: block;
    height: calc(100vh - #{$navbar-height});
    margin-top: $navbar-height;
    width: var(--aside-width);
    float: left;
  }
  main {
    display: block;
    height: calc(100vh - #{$navbar-height});
    margin-top: $navbar-height;
    width: calc(100vw - var(--aside-width));
    float: right;
    overflow: auto;
  }
}

button,
.box,
input,
select {
  border-radius: 0 !important;
}
