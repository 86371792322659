table .button-container {
  width: 225px;
}

table {
  .is-arrow {
    margin-left: 0.3rem;
  }
  .button-icon {
    max-width: 30px;
  }
  .button-icon-feasibility {
    max-height: 30px;
  }
  border-collapse: separate !important; /* Don't collapse, this allows the border to move with the header. */
}
