.outreach-page {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  //   justify-content: center;
  h1 {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .input-row {
    display: flex;
    flex-direction: center;
    justify-content: center;
    align-items: center;
  }
  .center-button {
    display: flex;
    flex-direction: center;
    justify-content: center;
    align-items: center;
  }
}
