.filter-container {
  .is-vertical-center {
    display: flex;
    align-items: center;
  }
  .filter-row {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0.5rem;
    .is-tooltip {
      display: block;
    }

    .column {
      padding: 0;
      margin: 0;
    }
    p {
      justify-content: center;
      height: 2.5rem;
    }
  }
  p {
    display: flex;
    align-items: center;
    line-height: 1;
  }

  .close-button {
    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
}
