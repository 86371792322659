.send-sms-container {
  overflow: auto;
  max-height: calc(100vh - 60px - 200px);

  .field {
    :not(&.is-marginless) {
      margin-bottom: 2rem !important;
    }
  }

  .margin-top {
    margin-top: 2rem;
  }

  .is-set-height {
    height: 64px;
    padding-left: 2em;
    padding-right: 2em;
  }

  .has-padding-left {
    padding-left: 0.5rem;
  }

  .send-buttons-box {
    margin-top: 2rem;
  }
}
