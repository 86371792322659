.schoolLookupContainer {
  margin-top: 10px;

  textarea {
    height: 300px;
    width: 300px;
  }
  .text-description {
    width: 100%;
  }
}

.butn-box {
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.look-up-box {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 2rem;
  margin-top: 1rem;

  textarea {
    text-align: justify;
    text-align-last: center;
    padding: calc(0.75em - 1px);
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
    font-size: 1rem;
    resize: none;
  }
}
