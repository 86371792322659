nav {
  .is-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .is-height-100 {
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }

  .is-spaced {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .loading-container {
    padding-right: 2rem;

    span {
      margin-right: 1rem;
    }

    img {
      height: 2rem;
    }
  }
}
