.view-email-container {
  max-height: 80vh;
  overflow: auto;
  .button-get-info-text {
    display: flex;
    flex-direction: column;
    justify-self: center;
    text-align: center;
  }

  .button-main-text {
    max-height: 1.3rem;
  }
  .button-secondary-text {
    max-height: 1rem;
    font-size: 0.7rem;
  }
}
