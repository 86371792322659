.status-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.status-card-header-button {
  max-width: 15rem;
  width: 20%;
  max-height: 48px;
}

.status-card-header-title {
  margin-top: 0.4rem;
  margin-bottom: 0.5rem;
  text-align: center;
  width: 60%;
}

.status-card-header-stauts {
  max-height: 48px;
  margin-top: 0;
  max-width: 15rem;
  margin-bottom: auto;
  position: relative;
  display: block;
  //   border-top-left-radius: 100rem;
  //   border-bottom-left-radius: 100rem;
  border-top-right-radius: 100rem;
  border-bottom-right-radius: 100rem;
  width: 20%;
  margin-right: 0;
}

.status-card-error-box {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
}

.status-card-error-box:last-child {
  max-width: 10rem;
}

// status-card-

.status-card-parameter-box-outer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // margin: 0 2rem;
  // margin-right: 2rem;
  font-size: 1rem;
  max-width: 120rem;
}

.status-card-parameter-box {
  padding: 1rem 2rem;
  max-width: 40rem;
  // background-color: var(--primary-light);
  border-radius: 1rem;
}

.status-card-parameter-box > * {
  min-width: 20rem;
  // color: white;
}

.status-card-parameter-box > *:not(:first-child) {
  margin-top: 0.2rem;
}
.status-card-parameter-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 1.5rem;
}
.status-card-parameter-row:nth-child(1) {
  // border-top: 2px solid var(--primary);
  border-bottom: 2px solid var(--primary);
  margin-bottom: 0.5rem;
}

.status-card-parameter-row:last-child {
  border-top: 2px solid var(--primary);
  // border-bottom: 2px solid var(--primary);
  margin-top: 0.5rem;
}

.status-card-footer {
  font-size: 0.9rem;
  padding: 0 0.5rem;
  position: relative;
}

.footer-left-val,
.footer-right-val {
  position: relative;
  bottom: -1rem;
}
