.sidebar {
  &.is-full-height {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .checkbox {
    .filterHeader-checkbox-label {
      margin-left: 0.5rem;
    }

    &:hover {
      color: white;
      font-weight: 700;
    }
  }

  .has-padding {
    padding: 2rem;
  }
  header.has-padding {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .is-bottom-container.is-spaced {
    :not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .filter-header-text {
    margin-right: 1rem;
    margin-bottom: 0 !important;
  }
  .filter-header-subtext {
    margin-left: 1rem;
  }

  .filter-component {
    padding-top: 1rem;
    height: auto;
    flex: 1;
    overflow: auto;
  }
}
.filter-component::-webkit-scrollbar {
  width: 0.5em;
}
.filter-component::-webkit-scrollbar-thumb {
  background-color: var(--success);
  border-radius: 1em;
}
